import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { LocationInfo } from "@/data/LocationInfo";
import { PersistContext } from "@/data/PersistProvider";
import { useEnhancedHistory } from "@/data/RouterProvider";
import { SendCMD } from "@/utils/HTTPRequest";
import { ChannelRemind } from "@/utils/UrlHelper";
import React, { useEffect, useRef, useState } from "react";
import style from "./style.less";

function filterNonDigits(input) {
  const result = input.replace(/\D/g, "");
  return result;
}
const PhoneErrorTextConst = "Please enter the correct phone number";
const PhoneStartWith0ErrorTextConst = 'The start "0" of your phone number is no need.';

const Forgot = () => {
  const history = useEnhancedHistory();
  const inputRef = useRef();
  const [phoneError, setPhoneError] = React.useState("");
  const [phoneErrorShakeNum, setPhoneErrorShakeNum] = useState(0);
  const { getPersist, setPersist } = React.useContext(PersistContext);
  const nowRegionInfo = LocationInfo();
  const [phone, setPhone] = React.useState(getPersist("last_phone") ? getPersist("last_phone") : "");

  const [formVilidated, setFormVilidated] = React.useState(false);

  const formSubmit = (e) => {
    if (!phone) {
      if (!phoneError) {
        setPhoneError(PhoneErrorTextConst);
      }
      setPhoneErrorShakeNum(phoneErrorShakeNum + 1);
      return;
    }

    //发送验证码请求
    SendCMD("sendVerificationCode", {
      phone: nowRegionInfo.phoneStartsWith + phone,
    }).then((res) => {
      if (!res.status) {
        setPhoneError(res.errdesc);
        return;
      } else {
        setPersist("last_phone", phone);
        history.replace(ChannelRemind("/verify"));
      }
    });

    e.preventDefault();
  };

  useEffect(() => {
    if (phone && !phoneError) {
      setFormVilidated(true);
    } else {
      setFormVilidated(false);
    }
  }, [phone, phoneError]);

  return (
    <div class={style.forgot_container}>
      <HomeHeaderOther title={"Find Account"} />
      <div class={style.forgot_top}>Find Account</div>
      <div class={style.forgot_reset_intro}>reset your password, please confirm your account first</div>
      <div class={style.login_form}>
        <div class={style.login_form_input}>
          <div class={style.phone_before}>
            <img src={nowRegionInfo.flag} />
          </div>
          <input
            class={style.auth_input}
            ref={inputRef}
            type="tel"
            maxLength={9}
            placeholder="Enter phone number"
            style={{ marginLeft: "0.52rem", fontSize: "1.1rem" }}
            defaultValue={getPersist("last_phone")}
            onChange={(e) => {
              let value = e.target.value;
              value = filterNonDigits(value);
              if (inputRef.current && inputRef.current.value !== value) {
                inputRef.current.value = value;
              }
              let regex = /^[1-9]{1}[0-9]{8}$/;
              if (value.startsWith("0")) {
                setPhone("");
                setPhoneError(PhoneStartWith0ErrorTextConst);
              } else if (regex.test(value)) {
                setPhone(value);
                setPhoneError("");
              } else if (value.length > 0) {
                setPhone("");
                setPhoneError(PhoneErrorTextConst);
              } else {
                setPhone("");
                setPhoneError("");
              }
            }}
          />
        </div>
        <div
          class={`${style.login_form_error} ${phoneErrorShakeNum > 0 ? style.shake : ""}`}
          key={`phoneErrorShakeNum${phoneErrorShakeNum}`}
        >{phoneError}</div>
        <div
          class={style.login_btn + (!formVilidated ? ` ${style.login_btn_disabled}` : "")}
          type="submit"
          onClick={formSubmit}
        >
          Continue
        </div>
      </div>
      <div class={style.footer}>
        <img src={require("@/assets/images/home/18_plus.png").default} />
        <span>© GameLuka all right reserved</span>
      </div>
    </div>
  );
};

export default Forgot;
