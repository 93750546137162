import React,{ useContext }  from "react";
import style from "./style.less";
import { Toast } from "react-vant";
import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PersistContext } from "@/data/PersistProvider";

const Contact = () => {
  const { getPersist } = useContext(PersistContext);
  const bgSettings = getPersist("bgSettings");
  let whatsApp = "85253053474";
  if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.whatsapp) {
    whatsApp = bgSettings.settings.client_config.whatsapp;
  }
  return (
    <div class={style.page_container}>
      <HomeHeaderOther title={"Contact us"} />
      <div class={style.contact_content}>
        <div class={style.contact_content_div}>
          <img
            src={require("@/assets/images/home/whats_app.png").default}
            style={{ width: "2.5rem", marginTop: "0.8rem", marginLeft: "1.5rem", marginBottom: "0.8rem" }}
          />
          <span>WhatsAPP: {whatsApp}</span>
          <CopyToClipboard
            text={whatsApp}
            onCopy={() => {
              Toast.success({
                message: "Copy Successfully",
              });
            }}
          >
            <img
              style={{ width: "1.7rem", height: "1.7rem", margin: "1.2rem 0 0 1.9rem" }}
              src={require("@/assets/icons/copy.png").default}
            />
          </CopyToClipboard>
        </div>

        <div class={style.footer_common}>
          <img src={require("@/assets/images/home/18_plus.png").default} />
          <span>
            <span class={style.symbol}>©</span> GameLuka all right reserved
          </span>
        </div>
      </div>
    </div>
  );
};

export default Contact;
