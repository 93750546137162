import enTranslation from '../lang/en.json';
//import ksTranslation from '../lang/ks.json';

export default {
    en: {
        name: "en",
        text: "English",
        langJson: enTranslation
    },
    // ks: {
    //     name: "ks",
    //     text: "Kiswahili",
    //     langJson: ksTranslation
    // }
};
