import { ChannelRemind } from "@/utils/UrlHelper";
import { useEnhancedHistory } from "@/data/RouterProvider";
const MyNavLink = (props) => {
  const history = useEnhancedHistory();

  const handleClick = (e) => {
    console.log("props.to:", props.to);
    let toHref = ChannelRemind(props.to);
    if (props.to && props.to.indexOf("mailto:") === 0) {
      window.open(props.to);
      return;
    }
    history.push(toHref); // 使用 history 进行页面跳转
    e.preventDefault();
  };
  return (
    <a {...props} onClick={handleClick}>
      {props.children}
    </a>
  );
};

export default MyNavLink;
