import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { LocationInfo } from "@/data/LocationInfo";
import { PersistContext } from "@/data/PersistProvider";
import { useEnhancedHistory } from "@/data/RouterProvider";
import { SendCMD } from "@/utils/HTTPRequest";
import { ChannelRemind } from "@/utils/UrlHelper";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Toast } from "react-vant";
import style from "./style.less";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PasswordErrorTextConst = "Password needs to be 6~16 characters!";

const SetNewPwd = () => {
  let query = useQuery();
  let code = query.get("code");

  const history = useEnhancedHistory();

  const [passwordError, setPasswordError] = React.useState("");
  const [formValidated, setFormValidated] = useState(false);
  const [password, setPassword] = React.useState("");
  const { getPersist } = React.useContext(PersistContext);
  const phone = getPersist("last_phone");

  const formSubmit = (e) => {
    if (!password) {
      setPasswordError(PasswordErrorTextConst);
      return;
    }
    if (password.length < 6 || password.length > 16) {
      setPasswordError(PasswordErrorTextConst);
      return;
    }

    //发送新密码请求
    SendCMD("resetPasswd", {
      phone: LocationInfo().phoneStartsWith + phone,
      new_passwd: password,
      code,
    }).then((res) => {
      if (!res.status) {
        console.log(res.errdesc);
        setPasswordError(res.errdesc);
        return;
      }
      Toast.info({
        message: "Password changed successfully",
        onClose: () => {
          history.replace(ChannelRemind("/login"));
        },
      });
    });

    e.preventDefault();
  };

  useEffect(() => {
    if (password && !passwordError) {
      setFormValidated(() => true);
    } else {
      setFormValidated(() => false);
    }
  }, [password, passwordError]);

  return (
    <div class={style.page_container_new_pwd}>
      <HomeHeaderOther title={"New Password"} />
      <div class={style.new_pwd_top}>Set New Password</div>
      <div class={style.new_pwd_form}>
        <div>
          <div class={style.new_pwd_input_container}>
            <input
              placeholder="New password"
              type={"text"}
              onChange={(e) => {
                let value = e.target.value;
                let regex = /^[a-zA-Z0-9_-]{6,16}$/;
                if (regex.test(value)) {
                  setPassword(value);
                  setPasswordError("");
                } else if (value.length > 0) {
                  setPassword("");
                  setPasswordError(PasswordErrorTextConst);
                } else {
                  setPassword("");
                  setPasswordError("");
                }
              }}
            />
          </div>
          <div class={style.new_pwd_form_error}>{passwordError}</div>
          <div style={{ margin: "1rem 1rem 0 1rem" }}>
            <div
              class={style.new_pwd_submit_btn + (!formValidated ? ` ${style.new_pwd_submit_btn_disabled}` : "")}
              onClick={formSubmit}
            >
              Set
            </div>
          </div>
        </div>
      </div>
      <div class={style.footer}>
        <img src={require("@/assets/images/home/18_plus.png").default} />
        <span>© GameLuka all right reserved</span>
      </div>
    </div>
  );
};

export default SetNewPwd;
