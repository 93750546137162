import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { LocationInfo } from "@/data/LocationInfo";
import { PersistContext } from "@/data/PersistProvider";
import { useEnhancedHistory } from "@/data/RouterProvider";
import { SendCMD } from "@/utils/HTTPRequest";
import { ChannelRemind } from "@/utils/UrlHelper";
import React, { useEffect } from "react";
import AuthCode from "react-auth-code-input";
import { CountDown } from "react-vant";
import style from "./style.less";

function filterNonDigits(input) {
  const result = input.replace(/\D/g, "");
  return result;
}
const CodeErrorTextConst = "Please enter the verification code";

const Verify = () => {
  const history = useEnhancedHistory();
  const [codeError, setCodeError] = React.useState("");
  const [formCorrect, setFormCorrect] = React.useState(false);
  const [code, setCode] = React.useState("");
  const { getPersist } = React.useContext(PersistContext);
  const phone = getPersist("last_phone");

  //倒计时， 重新发送按钮动态显示
  const [sendCodeEnable, setSendCodeEnable] = React.useState(false);

  const formSubmit = (e) => {
    if (!code) {
      setCodeError(CodeErrorTextConst);
      return;
    }

    //发送验证码校验请求
    SendCMD("checkVerificationCode", {
      code: code,
      phone: LocationInfo().phoneStartsWith + phone,
    }).then((res) => {
      if (!res.status) {
        setCodeError(res.errdesc);
        return;
      } else {
        history.replace(ChannelRemind("/setNewPwd?code=" + code));

        // Toast.info({message: "The verification code is sent successfully", onClose: () => {
        //         history.replace(ChannelRemind("/setNewPwd?code=" + code));
        //     }});
      }
    });

    e.preventDefault();
  };

  //再次发送验证码
  const sendAgainSubmit = (e) => {
    if (!phone) {
      // 电话号码为空，跳转到forgot页面
      history.replace(ChannelRemind("/forgot"));
      return;
    }

    //发送验证码请求
    SendCMD("sendVerificationCode", {
      phone: LocationInfo().phoneStartsWith + phone,
    }).then((res) => {
      if (!res.status) {
        setCodeError(res.errdesc);
        return;
      } else {
        //发送成功的逻辑处理： 展示倒计时
        setSendCodeEnable(false);
      }
    });

    e.preventDefault();
  };

  useEffect(() => {
    if (code && !codeError) {
      setFormCorrect(() => true);
    } else {
      setFormCorrect(() => false);
    }
  }, [code, codeError]);

  return (
    <div class={style.login_container}>
      <HomeHeaderOther title={"Verification"} />
      <div class={style.verify_top}>Enter verification code</div>
      <div class={style.forgot_intro}>
        The verification code has been sent to your phone{" "}
        <span>
          ({LocationInfo().phoneStartsWith}){phone}
        </span>
      </div>
      <div class={style.verify_form}>
        <div class={style.verify_code_form_inputs}>
          <AuthCode
            length={4}
            autoFocus={true}
            allowedCharacters="numeric"
            onChange={(value) => {
              value = filterNonDigits(value);
              if (value.length >= 4) {
                value = value.slice(0, 4);
              }
              if (value.length == 4) {
                setCode(value);
                setCodeError("");
              } else {
                setCode("");
                setCodeError("");
              }
            }}
          />
          <div>
            <div
              class={style.send_again_btn + (!sendCodeEnable ? ` ${style.send_again_btn_disabled}` : "")}
              onClick={sendAgainSubmit}
            >
              Send Again
              {!sendCodeEnable ? (
                <span style={{ display: "inline" }}>
                  {"("}
                  <CountDown
                    style={{
                      "--rv-count-down-text-color": "#fff",
                      display: "inline",
                    }}
                    time={60000}
                    format="sss"
                    onFinish={() => {
                      setSendCodeEnable(true);
                    }}
                  />
                  {")"}
                </span>
              ) : null}
            </div>
            <div class={style.login_form_verify_error}>{codeError}</div>
          </div>
        </div>

        <div
          class={style.login_btn + (formCorrect ? "" : ` ${style.login_btn_disabled}`)}
          type="submit"
          onClick={formSubmit}
        >
          Complete
        </div>
      </div>
      <div class={style.footer}>
        <img src={require("@/assets/images/home/18_plus.png").default} />
        <span>© GameLuka all right reserved</span>
      </div>
    </div>
  );
};

export default Verify;
