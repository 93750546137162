import Numerical from "./Numerical";
import Depoly from "./Depoly";
import Localization from "./Localization";
import Payment from "./Payment";
import Functional from "./Functional";
import LanguageTranslation from "./LanguageTranslation";

export const NumericalConfig = Numerical;
export const DepolyConfig = Depoly;
export const LocalConfig = Localization;
export const PaymentConfig = Payment;
export const FunctionalConfig = Functional;
export const LanguageConfig = LanguageTranslation;