import React, { createContext, useState, useContext } from 'react';
import { PersistContext } from "./PersistProvider";
import { LanguageConfig } from "@/config";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { getPersist, setPersist } = useContext(PersistContext);
  const [language, setLanguage] = useState(LanguageConfig.en);

  getPersist("language") && LanguageConfig[getPersist("language")] ? setLanguage(LanguageConfig[getPersist("language")]) : LanguageConfig.en

  const changeLanguage = (_language) => {
    setLanguage(LanguageConfig[_language]);
    setPersist("language", _language)
  };

  const getLanguage = () => {
    if (getPersist("language")) {
      return getPersist("language")
    }
    return LanguageConfig.en.name
  }

  const langText = (key, params = {}) => {
    let translation = language.langJson.text[key] || key;
    let paramIndex = 1;
    while (translation.includes(`[@]`)) {
      const paramKey = `param${paramIndex}`;
      if (params[paramKey] || params[paramKey] == 0) {
        translation = translation.replace(`[@]`, params[paramKey]);
      } else {
        break;
      }
      paramIndex++;
    }
    return translation;
  };

  const langImg = (key) => {
    return language.langJson.img[key] || key;
  }

  return (
    <LanguageContext.Provider value={{ language, getLanguage, changeLanguage, langText, langImg }}>
      {children}
    </LanguageContext.Provider>
  );
};
